<template>
  <div class="login">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
      <el-form-item class="marBot0">
        <h2>手机验证登录</h2>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input v-model="ruleForm.mobile" @focus="show=false" @blur="show=true" placeholder="手机号："></el-input>
      </el-form-item>
      <el-form-item prop="captchaCode" class="captchaBox">
        <el-input v-model="ruleForm.captchaCode" @focus="show=false" @blur="show=true" placeholder="图形验证码："></el-input>
        <img :src="'data:image/jpeg;base64,'+imgSrc" @click="refreshImg" />
      </el-form-item>
      <el-form-item prop="smsCode" class="captchaBox">
        <el-input v-model="ruleForm.smsCode" @focus="show=false" @blur="show=true" placeholder="验证码："></el-input>
        <el-button @click="getCode">发送</el-button>
      </el-form-item>
      <el-form-item prop="disclaimer" class="marBot16">
        <el-checkbox-group v-model="ruleForm.disclaimer">
          <el-checkbox>我已阅读并同意<router-link to="/disclaimer">免责条款</router-link>
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">登 录</el-button>
      </el-form-item>
    </el-form>
    
    <div class="tab" v-show="show">
      <router-link to="/login-pwd">账号登陆</router-link>
      <router-link to="/register">新用户注册</router-link>
      <router-link to="/reset-pwd">忘记密码</router-link>
    </div>
  </div>
</template>
<script>
import { getCaptchaImage,sendLoginSms,LoginSms } from "@/api/getData"

export default {
  name: "LoginSms",
  data() {
    return {
      imgSrc:'',
      ruleForm: {
        mobile: '',
        captchaCode: '',
        smsCode: '',
        disclaimer: []
      },
      show: true,
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        captchaCode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        disclaimer: [
          { type: 'array', required: true, message: '请查看免责条款', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    //图形验证码
    getCaptchaImage() {
      getCaptchaImage().then(res => {
        this.imgSrc = res
      })
    },
    //刷新图形验证码
    refreshImg() {
      this.getCaptchaImage()
    },
    //获取短信验证码
    getCode() {
      this.$refs.ruleForm.validateField(['captchaCode'], (val) => {
        if (!val) {
          //   return true;
          //sendRegisterSms
          let data = {
            captchaKey: this.vVerifycode,
            captchaCode: this.ruleForm.captchaCode,
            mobile: this.ruleForm.mobile
          }
          sendLoginSms(data).then(res => {
            console.log(res)
          })
        } else {
          return false;
        }
      })
    },
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            mobile: this.ruleForm.mobile,
            smsCode: this.ruleForm.smsCode
          }
          LoginSms(data).then(res => {
            this.$message.success('登录成功')
            this.$store.commit('SET_vToken', res.result.token)
            this.$store.commit('SET_vName', res.result.name)
            this.$store.commit('SET_vCompany', res.result.company)
            this.$store.commit('SET_vJob', res.result.job)
            this.$store.commit('SET_vDomains', res.result.domains)
            this.$store.commit('SET_vUserId', res.result.userId)
            this.$router.replace('/')
          })
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getCaptchaImage()
  }
}
</script>

<style lang="scss" scoped>
.login{
  padding: 0 0.16rem;
  overflow: hidden;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;
  h2{
    font-size: 0.18rem;
    margin: 0.4rem 0;
  }
  :deep(.el-checkbox__label){
    font-size: 0.12rem;
    padding-left: 0.04rem;
    a{
      color: #24BBD3;
    }
  }
  .tab{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    a{
      display: block;
      width: 33.3%;
      text-align: center;
      font-size: 0.12rem;
      color: #A8A8A8;
      line-height: 0.4rem;
      font-weight: normal;
    }
  }
}
</style>