<template>
  <div class="my">
    <div class="download-tips" v-show="downloadTips">
      <img src="@/assets/img/goback_icon.svg" alt="返回" draggable="false" class="back" @click="downloadTips = false">
      <img src="@/assets/img/arrow.svg" alt="" draggable="false" class="arrow">
      <p class="tips">
        1.请点击右上角“ <img src="@/assets/img/more.svg" draggable="false" alt=""> ”<br>2.选择用浏览器打开<br>3.点击下载获取资料
      </p>
    </div>
    <div v-show="!downloadTips">
      <div class="top">
        <router-link to="/disclaimer"><img src="@/assets/img/disclaimer.svg" alt=""></router-link>
        <router-link to="/setting"><img src="@/assets/img/personal_settings.svg" alt=""></router-link>
      </div>
      <div class="main">
        <div class="base-info">
          <img src="@/assets/img/head_portrait.png" alt="" class="head-portrait">
          <router-link to="/modifyData" class="name">
            <span>{{ vName }}</span><img src="@/assets/img/edit.svg" alt="" class="edit">
          </router-link>
          <p>{{ vCompany }}&nbsp;&nbsp;{{ vJob }}</p>
        </div>
        <el-tabs v-model="active">
          <el-tab-pane label="资料收藏" name="0">
            <div v-if="resourceCollectList.length">
              <div class="resources" v-for="resource in resourceCollectList" :key="resource.id">
                <p class="introduce">{{ resource.remark }}</p>
                <div class="label-wrap">
                  <button class="label">{{ resource.label }}</button>
                </div>
                <div class="file">
                  <img :src="require(`@/assets/img/file_type${resource.fileType}.svg`)" draggable="false" alt="">
                  <div>
                    <p class="file-name">{{ resource.name }}</p>
                    <div class="creation-time">{{ resource.creatDate }}</div>
                  </div>
                </div>
                <div class="resources-footer">
                  <button @click="collect(resource.id)">
                    <img :src="require(`@/assets/img/collection${resource.isisCollection ? '_active' : ''}.svg`)" draggable="false" alt="">
                    <span>收藏</span>
                  </button>
                  <button @click="download(resource)">
                    <img src="@/assets/img/download.svg" draggable="false" alt="">
                    <span>下载</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="empty" v-else>
              <img src="@/assets/img/empty.svg" alt="">
              <div class="empty-msg">当前暂无收藏资料</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="文章收藏" name="1">
            <div v-if="articleCollectionList.length">
              <div class="article" v-for="article in articleCollectionList" :key="article.id" @click="jump(article.id)">
                <img :src="article.imgUrl" draggable="false" alt="">
                <div>
                  <p class="article-title">{{ article.title }}</p>
                  <div class="creation-time">{{ article.ctime }}</div>
                </div>
              </div>
            </div>
            <div class="empty" v-else>
              <img src="@/assets/img/empty.svg" alt="">
              <div class="empty-msg">当前暂无收藏文章</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="测评报告" name="2">
            <div class="report" v-if="reportData">
              <h4>体适能智能运动测评系统</h4>
              <p class="des">体适能是指大众适应日常生活、休闲娱乐和运动，能够应对突发事件和紧急情况，足以安全有效地应付日常生活和身体所承受的冲击和负荷所具备的身体能力。</p>
              <p class="des">本系统采用科学方式，从平衡、柔韧、协调、灵敏、爆发力等多方面进行综合测评，全面反映个人体适能水平。快来看看您的运动能力吧，并记得每隔3个月进行定期测评喔！</p>
              <h3>测评总结</h3>
              <p class="date">{{reportData.baseInfo.createDate}}</p>
              <img src="@/assets/img/baseInfo_header1.svg" alt="" class="baseInfo_header" v-if="reportData.baseInfo.totalScore > 95">
              <img src="@/assets/img/baseInfo_header4.svg" alt="" class="baseInfo_header" v-else-if="reportData.baseInfo.totalScore > 85 && reportData.baseInfo.totalScore <= 95">
              <img src="@/assets/img/baseInfo_header3.svg" alt="" class="baseInfo_header" v-else-if="reportData.baseInfo.totalScore > 70 && reportData.baseInfo.totalScore <= 85">
              <img src="@/assets/img/baseInfo_header2.svg" alt="" class="baseInfo_header" v-else>
              <div class="baseInfoBox">
                <div class="baseInfoDet">
                  <div class="baseInfoCnt">
                    <img src="@/assets/img/baseInfo_icon1.svg">
                    <span>姓名：{{reportData.baseInfo.name}}</span>
                  </div>
                  <div class="baseInfoCnt">
                    <img src="@/assets/img/baseInfo_icon2.svg">
                    <span>身高：{{reportData.baseInfo.height}} cm</span>
                  </div>
                  <div class="baseInfoCnt">
                    <img src="@/assets/img/baseInfo_icon3.svg">
                    <span>性别：{{reportData.baseInfo.sex}}</span>
                  </div>
                  <div class="baseInfoCnt">
                    <img src="@/assets/img/baseInfo_icon4.svg">
                    <span>体重：{{reportData.baseInfo.weight}}kg</span>
                  </div>
                  <div class="baseInfoCnt">
                    <img src="@/assets/img/baseInfo_icon5.svg">
                    <span>年龄：{{reportData.baseInfo.age}}岁</span>
                  </div>
                  <div class="baseInfoCnt">
                    <img src="@/assets/img/baseInfo_icon6.svg">
                    <span>BMI：{{reportData.baseInfo.bmi}}</span>
                  </div>
                </div>
                <p class="baseInfoRemark">{{reportData.baseInfo.remark}}</p>
              </div>
              <div class="dashBoardBox" id="dashBoard"></div>
              <div class="radarMapBox" id="radarMap"></div>
              <h3>数据展示</h3>
              <div class="showBox">
                <h5>一字平衡</h5>
                <div class="scoreBox">
                  <div class="starGrey">
                    <img src="@/assets/img/star.svg" v-for="item in 5" :key="item">
                  </div>
                  <div class="starActive" v-if="reportData.balanceInfo.score === 5">
                    <img src="@/assets/img/star_active_orange.svg" v-for="item in reportData.balanceInfo.score" :key="item">
                  </div>
                  <div class="starActive" v-else-if="reportData.balanceInfo.score < 5 && reportData.balanceInfo.score > 2">
                    <img src="@/assets/img/star_active_yellow.svg" v-for="item in reportData.balanceInfo.score" :key="item">
                  </div>
                  <div class="starActive" v-else>
                    <img src="@/assets/img/star_active_blue.svg" v-for="item in reportData.balanceInfo.score" :key="item">
                  </div>
                </div>
                <p class="cLevel" :class="reportData.balanceInfo.leftLevel == '优秀' ? 'orange' : reportData.balanceInfo.leftLevel == '良好' ? 'yellow' : reportData.balanceInfo.leftLevel == '一般' ? 'blue' : 'grey'">{{reportData.balanceInfo.leftLevel}}</p>
                <img src="@/assets/img/balance_img.svg" class="actionPic">
                <p class="dataDet">屈伸：{{reportData.balanceInfo.angleSagittal}}°</p>
                <p class="dataDet">侧伸：{{reportData.balanceInfo.angleCoronal}}°</p>
                <p class="dataDet">旋转：{{reportData.balanceInfo.angleTransverse}}°</p>
                <p class="dataDet">失去平衡次数：{{reportData.balanceInfo.uninlinedFootCount}}次</p>
                <h6>
                  <span class="border"></span>
                  <span class="title">平衡性</span>
                </h6>
                <p class="motionDes">平衡性是指身体位置和姿态变化时自动调整并维持姿势的能力，发展平衡能力能促进中枢神经系统对肌肉的调节，提高适应复杂环境和自我保护的能力，可以通过参加平衡车、滑雪、滑板等项目来提高！</p>
              </div>

              <div class="showBox">
                <h5>直立转体</h5>
                <div class="scoreBox">
                  <div class="starGrey">
                    <img src="@/assets/img/star.svg" v-for="item in 5" :key="item">
                  </div>
                  <div class="starActive" v-if="reportData.uprightSwivel.score === 5">
                    <img src="@/assets/img/star_active_orange.svg" v-for="item in reportData.uprightSwivel.score" :key="item">
                  </div>
                  <div class="starActive" v-else-if="reportData.uprightSwivel.score < 5 && reportData.uprightSwivel.score > 2">
                    <img src="@/assets/img/star_active_yellow.svg" v-for="item in reportData.uprightSwivel.score" :key="item">
                  </div>
                  <div class="starActive" v-else>
                    <img src="@/assets/img/star_active_blue.svg" v-for="item in reportData.uprightSwivel.score" :key="item">
                  </div>
                </div>
                <p class="cLevel">
                  <span :class="reportData.uprightSwivel.leftLevel == '优秀' ? 'orange' : reportData.uprightSwivel.leftLevel == '良好' ? 'yellow' : reportData.uprightSwivel.leftLevel == '一般' ? 'blue' : 'grey'">左旋：{{reportData.uprightSwivel.leftLevel}}</span>
                  <span :class="reportData.uprightSwivel.rightLevel == '优秀' ? 'orange' : reportData.uprightSwivel.rightLevel == '良好' ? 'yellow' : reportData.uprightSwivel.rightLevel == '一般' ? 'blue' : 'grey'">右旋：{{reportData.uprightSwivel.rightLevel}}</span>
                </p>
                <img src="@/assets/img/swivel_img.svg" class="actionPic">
                <p class="dataDet">总活动度: {{reportData.uprightSwivel.activityCount}}°</p>
                <p class="dataDet">左旋: {{reportData.uprightSwivel.left}}°</p>
                <p class="dataDet">右旋: {{reportData.uprightSwivel.right}}°</p>
                <p class="dataDet">两侧差异: {{reportData.uprightSwivel.difference}}</p>
                <h6>
                  <span class="border"></span>
                  <span class="title">柔韧性</span>
                </h6>
                <p class="motionDes">柔韧性是指人体关节以及周围软组织所具备的弹性和伸展能力，是决定功能活动范围的基本要素，也是运动损伤筛查的重要组成部分，可以通过参加街舞、综训等活动来提高！</p>
              </div>

              <div class="showBox">
                <h5>直立侧屈</h5>
                <div class="scoreBox">
                  <div class="starGrey">
                    <img src="@/assets/img/star.svg" v-for="item in 5" :key="item">
                  </div>
                  <div class="starActive" v-if="reportData.uprightGrief.score === 5">
                    <img src="@/assets/img/star_active_orange.svg" v-for="item in reportData.uprightGrief.score" :key="item">
                  </div>
                  <div class="starActive" v-else-if="reportData.uprightGrief.score < 5 && reportData.uprightGrief.score > 2">
                    <img src="@/assets/img/star_active_yellow.svg" v-for="item in reportData.uprightGrief.score" :key="item">
                  </div>
                  <div class="starActive" v-else>
                    <img src="@/assets/img/star_active_blue.svg" v-for="item in reportData.uprightGrief.score" :key="item">
                  </div>
                </div>
                <p class="cLevel">
                  <span :class="reportData.uprightGrief.leftLevel == '优秀' ? 'orange' : reportData.uprightGrief.leftLevel == '良好' ? 'yellow' : reportData.uprightGrief.leftLevel == '一般' ? 'blue' : 'grey'">左侧屈：{{reportData.uprightGrief.leftLevel}}</span>
                  <span :class="reportData.uprightGrief.rightLevel == '优秀' ? 'orange' : reportData.uprightGrief.rightLevel == '良好' ? 'yellow' : reportData.uprightGrief.rightLevel == '一般' ? 'blue' : 'grey'">右侧屈：{{reportData.uprightGrief.rightLevel}}</span>
                </p>
                <img src="@/assets/img/flexion_img.svg" class="actionPic">
                <p class="dataDet">总活动度: {{reportData.uprightGrief.activityCount}}°</p>
                <p class="dataDet">左旋: {{reportData.uprightGrief.left}}°</p>
                <p class="dataDet">右旋: {{reportData.uprightGrief.right}}°</p>
                <p class="dataDet">两侧差异: {{reportData.uprightGrief.difference}}</p>
                <h6>
                  <span class="border"></span>
                  <span class="title">协调性</span>
                </h6>
                <p class="motionDes">协调性是指机体不同系统、不同部位、不同器官协同配合的能力，是形成运动技能的基石，有助于青少年儿童掌握运动技巧，减少运动损伤，可以通过参加跳绳、户外活动来提高！</p>
              </div>

              <div class="showBox">
                <h5>十字象限跳</h5>
                <div class="scoreBox">
                  <div class="starGrey">
                    <img src="@/assets/img/star.svg" v-for="item in 5" :key="item">
                  </div>
                  <div class="starActive" v-if="reportData.crossQuadrantJump.score === 5">
                    <img src="@/assets/img/star_active_orange.svg" v-for="item in reportData.crossQuadrantJump.score" :key="item">
                  </div>
                  <div class="starActive" v-else-if="reportData.crossQuadrantJump.score < 5 && reportData.crossQuadrantJump.score > 2">
                    <img src="@/assets/img/star_active_yellow.svg" v-for="item in reportData.crossQuadrantJump.score" :key="item">
                  </div>
                  <div class="starActive" v-else>
                    <img src="@/assets/img/star_active_blue.svg" v-for="item in reportData.crossQuadrantJump.score" :key="item">
                  </div>
                </div>
                <p class="cLevel">
                  <span :class="reportData.crossQuadrantJump.leftLevel == '优秀' ? 'orange' : reportData.crossQuadrantJump.leftLevel == '良好' ? 'yellow' : reportData.crossQuadrantJump.leftLevel == '一般' ? 'blue' : 'grey'">顺时针：{{reportData.crossQuadrantJump.leftLevel}}</span>
                  <span :class="reportData.crossQuadrantJump.rightLevel == '优秀' ? 'orange' : reportData.crossQuadrantJump.rightLevel == '良好' ? 'yellow' : reportData.crossQuadrantJump.rightLevel == '一般' ? 'blue' : 'grey'">逆时针：{{reportData.crossQuadrantJump.rightLevel}}</span>
                </p>
                <img src="@/assets/img/quadrantJump_img.svg" class="actionPic">
                <p class="dataDet">总用时: {{reportData.crossQuadrantJump.timeCount}}</p>
                <p class="dataDet">顺时针：{{reportData.crossQuadrantJump.clocktime}}S</p>
                <p class="dataDet">逆时针：{{reportData.crossQuadrantJump.anticlocktime}}S</p>
                <p class="dataDet">顺逆差异: {{reportData.crossQuadrantJump.difference}}</p>
                <h6>
                  <span class="border"></span>
                  <span class="title">灵敏性</span>
                </h6>
                <p class="motionDes">灵敏性是指急起、急停、变向、再加速等快速改变运动行为和身体姿态的能力，强调对外界变化快速反应，是许多青少年儿童运动项目的基础能力，可以通过参加跑步、跳绳、平衡车等项目来提高！</p>
              </div>

              <div class="showBox">
                <h5>原地纵跳</h5>
                <div class="scoreBox">
                  <div class="starGrey">
                    <img src="@/assets/img/star.svg" v-for="item in 5" :key="item">
                  </div>
                  <div class="starActive" v-if="reportData.standJump.score === 5">
                    <img src="@/assets/img/star_active_orange.svg" v-for="item in reportData.standJump.score" :key="item">
                  </div>
                  <div class="starActive" v-else-if="reportData.standJump.score < 5 && reportData.standJump.score > 2">
                    <img src="@/assets/img/star_active_yellow.svg" v-for="item in reportData.standJump.score" :key="item">
                  </div>
                  <div class="starActive" v-else>
                    <img src="@/assets/img/star_active_blue.svg" v-for="item in reportData.standJump.score" :key="item">
                  </div>
                </div>
                <p class="cLevel" :class="reportData.standJump.leftLevel == '优秀' ? 'orange' : reportData.standJump.leftLevel == '良好' ? 'yellow' : reportData.standJump.leftLevel == '一般' ? 'blue' : 'grey'">{{reportData.standJump.leftLevel}}</p>
                <img src="@/assets/img/verticalJump_img.svg" class="actionPic">
                <p class="dataDet">起跳高度: {{reportData.standJump.takeoffHeight}}CM</p>
                <p class="dataDet">滞空时间: {{reportData.standJump.hangTime}}S</p>
                <p class="dataDet">起跳做功: {{reportData.standJump.takeOff}}J</p>
                <p class="dataDet">峰值功率: {{reportData.standJump.peakPower}}W</p>
                <h6>
                  <span class="border"></span>
                  <span class="title">爆发力</span>
                </h6>
                <p class="motionDes">爆发力是指人体克服外界阻力做功时的输出功率，是青少年儿童体能发展的基础，对于提高运动表现和预防运动损伤具有重要意义，可以通过参加篮球、足球等项目来提高！</p>
              </div>

              <img src="@/assets/img/ewm.png" class="ewm">
              <img src="@/assets/img/noitom_logo.svg" class="noitom_logo">
              <p class="footer">www.noitom.com.cn 新街口外大街28号A座5层</p>
            </div>
            <div class="empty" v-else>
              <img src="@/assets/img/empty.svg" alt="">
              <div class="empty-msg">当前暂无报告</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getResourcesCollect,
  resourcesCollect,
  getArticlesCollect,
  getResourcesLabels,
  downloadResource,
  getReportData, getResourceType
} from '@/api/getData'
import { is_weixn } from "@/utils/tool";

export default {
  name: "My",
  data() {
    return {
      active: '0',
      downloadTips: false,
      resourceLabelList: [],
      resourceCollectList: [],
      articleCollectionList: [],
      reportData: {
        baseInfo: {
          name: "",
          age: null,
          sex: "",
          height: null,
          weight: null,
          bmi: null,
          totalScore: null,
          remark: "",
          createDate: ""
        },
        balanceInfo: {
          uninlinedFootCount: null,
          angleSagittal: null,
          angleCoronal: null,
          angleTransverse: null,
          score: null,
          levelName: null,
          leftLevel: ''
        },
        uprightSwivel: {  //直立转体
          activityCount: null, //总活动度
          difference: "", //两侧差异
          left: null,  //左侧
          right: null,
          score: null
        },

        uprightGrief: { //直立恻曲
          activityCount: null, //总活动度
          difference: "",  //两侧差异
          left: null,
          right: null,
          score: null
        },
        crossQuadrantJump: { //十字象限跳信息
          timeCount: "", //总时长
          difference: "", //差异
          clocktime: "", //顺时针时间
          anticlocktime: "", //逆时针时间
          score: null
        },
        standJump: {  //原地纵跳
          hangTime: "", //滞空时间
          takeOff: "",  //起跳做功
          peakPower: "",  //峰值功率
          takeoffHeight: "",  //起跳高度
          score: null
        },
      },
    }
  },
  created() {
    this.getResourcesLabelsList()
    this.getResourceTypeList()
  },
  mounted() {
    this.getReportData()
  },
  watch: {
    active: function (val, oldVal) {
      if (val !== oldVal) {
        if (val === '0') {
          this.getResourceCollectList()
        } else if (val === '1') {
          this.getArticleCollectList()
        } else if (val === '2') {

        }
      }
    }
  },
  methods: {
    drawGauge() {
      //仪表盘
      var dashBoardChart = this.$echarts.init(document.getElementById('dashBoard'));
      var dashBoardOption = {
        series: [
          {
            type: 'gauge',
            max: 100, // dashBoard最大值
            radius: 67,
            progress: {
              show: true,
              width: 10,
              roundCap: true
            },
            itemStyle: {
              color: '#24BBD3'
            },
            axisLine: {
              lineStyle: {
                width: 10,
                color: [[1, '#efefef']]
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisLabel: {
              show: false
            },
            anchor: {
              show: false,
            },

            title: {
              offsetCenter: [0, 75],
              fontSize: 14,
              lineHeight: 14
            },
            detail: {
              valueAnimation: false,
              fontSize: 40,
              color: '#24BBD3',
              offsetCenter: [0, 5]
            },
            data: [
              {
                value: this.reportData.baseInfo.totalScore,
                name: '测评综合得分'
              }
            ]
          }
        ]
      };
      dashBoardChart.setOption(dashBoardOption);
    },
    drawRadarMap() {
      //雷达图
      var myChart = this.$echarts.init(document.getElementById('radarMap'));
      var option = {
        title: {
          text: ''
        },
        tooltip: {},
        legend: {
          bottom: 5,
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: 'black',
              background: '#fff',
              borderRadius: 3,
              padding: [3, -10],
              fontWeight: 'bold'
            }
          },
          splitNumber: 5,
          shape: 'circle',
          splitArea: {
            areaStyle: {
              // color:['#FFCC00'],
              color: ['#fff'],
              shadowColor: 'rgba(0, 0, 0, 0)',
              shadowBlur: 10
            }
          },
          indicator: [
            //max为当前动作的最大值
            { name: '平衡', max: 5, color: '#24BBD3' },
            { name: '柔韧', max: 5, color: '#24BBD3' },
            { name: '灵敏', max: 5, color: '#24BBD3' },
            { name: '爆发力', max: 5, color: '#24BBD3' },
            { name: '协调', max: 5, color: '#24BBD3' }
          ],

          splitLine: {
            lineStyle: {
              color: ['#24BBD3'],
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#24BBD3'
            }
          },
        },
        series: [{
          type: 'radar',
          symbol: 'none',
          // areaStyle: {normal: {}},
          lineStyle: {
            width: 3,
            color: 'rgba(0,0,0,0)'
          },
          data: [
            {
              value: this.reportData.radar,
              // color: '#24BBD3',

              //设置雷达图中的显示的颜色
              areaStyle: {
                color: new this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                  {
                    color: '#24BBD3',
                    offset: 0
                  },
                  {
                    color: '#24BBD3',
                    offset: 1
                  }
                ])
              }
            },
          ]
        }]
      };

      myChart.setOption(option);
    },
    //获取收藏的资料列表
    getResourceCollectList() {
      getResourcesCollect().then(res => {
        this.resourceCollectList = res.result
        this.resourceCollectList.forEach(item => {
          this.resourceLabelList.forEach(ele => {
            if (item.labelId === ele.id) {
              item.label = ele.name
            }
          })
        })
      }).catch(error => {
        console.log(error)
      })
    },
    //获取收藏的文章列表
    getArticleCollectList() {
      getArticlesCollect().then(res => {
        this.articleCollectionList = res.result
      }).catch(error => {
        console.log(error)
      })
    },
    //获取资料标签
    getResourcesLabelsList() {
      getResourcesLabels().then(res => {
        this.resourceLabelList = res.result
        this.getResourceCollectList()
      }).catch(error => {
        console.log(error)
      })
    },
    //获取资料类型列表
    getResourceTypeList() {
      getResourceType().then(res => {
        this.resourceTypeList = res.result
      }).catch(error => {
        console.log(error)
      })
    },
    //取消收藏资料
    collect(resourceId) {
      resourcesCollect(resourceId).then(() => {
        this.$message.success('取消收藏成功')
        this.getResourceCollectList()
      }).catch(error => {
        console.log(error)
      })
    },
    //跳转到文章详情
    jump(articleId) {
      this.$router.push({ path: '/medicalDetails', query: { articleId } })
    },
    //下载资料
    download(resource) {
      if (this.$store.state.vToken) {
        if (is_weixn()) {
          this.downloadTips = true
        } else {
          downloadResource(resource.id).then(res => {
            const type = this.resourceTypeList.find(item => item.id === resource.fileType)
            const blob = new Blob([res.data], { type: type.name })
            const fileName = resource.name + '.' + type.name
            const blobUrl = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.style.display = 'none'
            link.download = fileName
            link.href = blobUrl
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href)
            document.body.removeChild(link)
          }).catch(error => {
            console.log(error)
          })
        }
      } else {
        this.$router.push('/login-pwd')
      }
    },
    getReportData() {
      getReportData().then(res => {
        // if(res.result == null){
        //   console.log(1)
        //   this.reportData = null
        // }
        if (Object.keys(res.result).length == 0) {
          this.reportData = null
        } else {
          //todo
          this.reportData = res.result
          this.drawGauge()
          this.drawRadarMap()
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my {
  position: relative;
  padding-top: 1.18rem;
  background-color: #24bbd3;

  .top {
    position: absolute;
    top: 0.16rem;
    right: 0.16rem;

    a {
      display: inline-block;
      width: 0.31rem;
      height: 0.31rem;
      margin-left: 0.16rem;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .main {
    position: relative;
    padding: 1.46rem 0 0;
    background-color: #fff;
    border-radius: 0.08rem 0.08rem 0 0;

    .base-info {
      position: absolute;
      top: -0.62rem;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      color: rgba(0, 0, 0, 0.9);

      .head-portrait {
        width: 1.24rem;
        border-radius: 50%;
      }

      .name {
        height: 0.2rem;
        display: flex;
        justify-content: center;
        font-size: 0.2rem;
        font-weight: 500;
        margin: 0.16rem 0;
      }

      .edit {
        width: 0.14rem;
        padding: 0.03rem 0 0.03rem 0.14rem;
      }

      > p {
        white-space: nowrap;
        font-size: 0.1rem;
      }
    }

    :deep(.el-tabs) {
      .el-tabs__header {
        margin-bottom: 0.24rem;
      }
      .el-tabs__content {
        overflow: visible;
        padding: 0 0.16rem;
      }
    }

    .article {
      height: 1.02rem;
      display: flex;
      justify-content: space-between;
      padding: 0.12rem 0.1rem 0.12rem 0.12rem;
      box-shadow: 0.03rem 0.04rem 0.05rem 0 rgba(255, 255, 255, 0.3),
        0 0.03rem 0.05rem 0.03rem rgba(50, 111, 121, 0.05),
        0 0 0.12rem 0 rgba(112, 214, 231, 0.08);

      > img {
        width: 1.01rem;
        height: 0.78rem;
      }

      > div {
        width: 2.03rem;
      }

      .article-title {
        width: 100%;
        height: 0.48rem;
        line-height: 0.24rem;
        font-size: 0.13rem;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 0.19rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .creation-time {
        font-size: 0.1rem;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .empty {
      position: relative;

      > img {
        display: block;
        width: 3.45rem;
      }

      .empty-msg {
        position: absolute;
        bottom: 0.16rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.1rem;
        color: rgba(0, 0, 0, 0.9);
      }
    }
    .report {
      h4 {
        color: #24bbd3;
        font-size: 0.14rem;
        line-height: 0.24rem;
        padding-bottom: 0.08rem;
        border-bottom: 2px solid #24bbd3;
      }
      .des {
        font-size: 0.14rem;
        line-height: 0.24rem;
        margin-top: 0.24rem;
      }
      h3 {
        margin-top: 0.3rem;
        background: url("/articleImg/title_bg.png") no-repeat center;
        height: 0.52rem;
        line-height: 0.52rem;
        text-align: center;
        color: #0596ad;
        font-size: 0.18rem;
      }
      .date {
        font-size: 0.1rem;
        color: rgba(0, 0, 0, 0.4);
        margin: 0.16rem 0;
        text-align: center;
      }
      .baseInfo_header {
        display: block;
        width: 100%;
      }
      .baseInfoBox {
        margin: 0 0.08rem;
        height: 3.5rem;
        border-radius: 4px;
        background: rgba(255, 143, 31, 0.06);
        box-sizing: border-box;
        border: 1.2px solid #ff8f1f;
        margin-top: -0.2rem;
        .baseInfoDet {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid #ff8f1f;
          padding: 0.2rem 0.3rem;
          .baseInfoCnt {
            font-size: 0.14rem;
            margin-top: 0.2rem;
            img {
              width: 0.2rem;
              vertical-align: middle;
              margin-right: 0.04rem;
            }
            span {
              vertical-align: middle;
            }
          }
          .baseInfoCnt:nth-child(odd) {
            width: 55%;
          }
          .baseInfoCnt:nth-child(even) {
            width: 45%;
          }
        }
        .baseInfoRemark {
          font-size: 0.14rem;
          line-height: 0.24rem;
          padding: 0.24rem 0.3rem;
        }
      }
      .dashBoardBox {
        height: 2.3rem;
        border-radius: 4px;
        background: rgba(219, 254, 255, 0.04);
        box-sizing: border-box;
        border: 1.2px solid rgba(7, 185, 185, 0.2);
        margin: 0.24rem 0.08rem 0;
        width: 3.25rem;
      }
      .radarMapBox {
        height: 3.18rem;
        border-radius: 4px;
        background: rgba(219, 254, 255, 0.04);
        box-sizing: border-box;
        border: 1.2px solid rgba(7, 185, 185, 0.2);
        margin: 0.24rem 0.08rem 0;
        margin-top: 0.32rem;
        width: 3.25rem;
      }
      .showBox {
        border-radius: 4px;
        background: rgba(219, 254, 255, 0.04);
        box-sizing: border-box;
        border: 1.2px solid rgba(7, 185, 185, 0.2);
        padding: 0.32rem 0.24rem;
        width: 3.25rem;
        margin: 0.32rem 0.08rem 0;
        h5 {
          font-size: 0.14rem;
          line-height: 0.24rem;
          text-align: center;
        }
        .scoreBox {
          position: relative;
          margin-top: 0.16rem;
          .starGrey {
            margin-left: 0.8rem;
            img {
              margin: 0 0.03rem;
              width: 0.17rem;
            }
          }
          .starActive {
            position: absolute;
            top: 0;
            left: 0.8rem;
            img {
              margin: 0 0.03rem;
              width: 0.17rem;
            }
          }
        }
        .cLevel {
          font-size: 0.14rem;
          // color: #ffc300;
          text-align: center;
          margin: 0.2rem 0;
          font-weight: bold;
          span {
            margin: 0 0.2rem;
          }
        }
        .orange {
          color: #ff8f1f;
        }
        .yellow {
          color: #ffc300;
        }
        .blue {
          color: #23aeff;
        }
        .grey {
          color: #dcdddd;
        }
        .actionPic {
          display: block;
          margin: 0 auto;
          width: 1.53rem;
        }
        .dataDet {
          height: 0.45rem;
          border-radius: 4px;
          background: rgba(219, 254, 255, 0.4);
          box-sizing: border-box;
          border: 1.2px solid rgba(7, 185, 185, 0.2);
          line-height: 0.45rem;
          text-align: center;
          font-size: 0.14rem;
          color: #0596ad;
          margin-top: 0.16rem;
        }
        h6 {
          margin-top: 0.4rem;
          .border {
            display: inline-block;
            width: 0.04rem;
            height: 0.16rem;
            border-radius: 0.1rem;
            background: #24bbd3;
            vertical-align: middle;
          }
          .title {
            font-size: 0.16rem;
            vertical-align: middle;
            margin-left: 0.08rem;
          }
        }
        .motionDes {
          font-size: 0.14rem;
          line-height: 0.24rem;
          margin-top: 0.16rem;
        }
      }
      .ewm {
        display: block;
        width: 1.64rem;
        margin: 0.4rem auto 0.1rem;
      }
      .noitom_logo {
        display: block;
        width: 1.4rem;
        margin: 0 auto;
      }
      .footer {
        text-align: center;
        font-size: 0.12rem;
        margin: 0.2rem 0;
        color: rgba(61, 61, 61, 0.6);
      }
    }
  }
}
</style>