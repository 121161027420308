import Vue from 'vue'
import Vuex from 'vuex'
import {
  setStore,
  getStore,
  clearStore
} from '@/utils/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vToken: getStore({
      name: 'vToken'
    }) || '',
    vVerifycode: getStore({
      name: 'vVerifycode'
    }) || '',
    vName: getStore({
      name: 'vName'
    }) || '',
    vCompany: getStore({
      name: 'vCompany'
    }) || '',
    vJob: getStore({
      name: 'vJob'
    }) || '',
    vDomains: getStore({
      name: 'vDomains'
    }) || '',
    vUserId: getStore({
      name: 'vUserId'
    }) || '',
  },
  mutations: {
    SET_vUserId: (state, vUserId) => {
      state.vUserId = vUserId;
      setStore({
        name: 'vUserId',
        content: state.vUserId
      })
    },
    SET_vToken: (state, vToken) => {
      state.vToken = vToken;
      setStore({
        name: 'vToken',
        content: state.vToken
      })
    },
    SET_vVerifycode: (state, vVerifycode) => {
      state.vVerifycode = vVerifycode;
      setStore({
        name: 'vVerifycode',
        content: state.vVerifycode
      })
    },
    SET_vName(state, vName) {
      state.vName = vName
      setStore({
        name: 'vName',
        content: state.vName
      })
    },
    SET_vCompany(state, vCompany) {
      state.vCompany = vCompany
      setStore({
        name: 'vCompany',
        content: state.vCompany
      })
    },
    SET_vJob(state, vJob) {
      state.vJob = vJob
      setStore({
        name: 'vJob',
        content: state.vJob
      })
    },
    SET_vDomains(state, vDomains) {
      state.vDomains = vDomains
      setStore({
        name: 'vDomains',
        content: state.vDomains
      })
    },
    Logout: (state) => {
      state.vToken = state.vVerifycode = state.vName = state.vCompany = state.vJob = state.vUserId = state.vDomains = ''
      clearStore();
    },
  },
  actions: {
    // Logout() {
    //   console.log("store Logout")
    //   clearStore();
    // }
  },
  getters: {
    vToken: state => state.vToken,
    vVerifycode: state => state.vVerifycode,
    vName: state => state.vName,
    vCompany: state => state.vCompany,
    vJob: state => state.vJob,
    vDomains: state => state.vDomains,
    vUserId: state => state.vUserId
  },
  modules: {
  }
})
