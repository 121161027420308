<template>
  <div class="home">
    <router-view></router-view>
    <div class="tabbar">
      <div class="menu_list">
        <router-link to="/college" :class="$route.path === '/college' ? 'is-active' : ''">
          <img :src="require(`@/assets/img/college_${$route.path === '/college' ? 'active' : 'icon'}.svg`)" alt="">
          <p>知见学院</p>
        </router-link>
      </div>
      <div class="menu_list">
        <router-link to="/download" :class="$route.path === '/download' ? 'is-active' : ''">
          <img :src="require(`@/assets/img/download_${$route.path === '/download' ? 'active' : 'icon'}.svg`)" alt="">
          <p>资料下载</p>
        </router-link>
      </div>
      <div class="menu_list">
        <router-link to="/my" :class="$route.path === '/my' ? 'is-active' : ''">
          <img :src="require(`@/assets/img/my_${$route.path === '/my' ? 'active' : 'icon'}.svg`)" alt="">
          <p>个人中心</p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
    }
  },
  updated() {
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.home {
  .tabbar {
    height: 0.58rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    border-top: 2px solid rgba(0, 0, 0, 0.08);
    color: #3D3D3D;
    background-color: #fff;

    a.is-active {
      color: #24BBD3;
    }

    img {
      display: block;
      width: 0.28rem;
      margin: 0.06rem auto 0;
    }
    p {
      font-size: 0.1rem;
      margin-top: 0.06rem;
    }
  }
}
</style>