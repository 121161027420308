<template>
  <div class="register">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm" v-show="!domainsDialog">
      <el-form-item class="marBot0">
        <h2>01 账号信息</h2>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input v-model="ruleForm.mobile" placeholder="手机号："></el-input>
      </el-form-item>
      <el-form-item prop="captchaCode" class="captchaBox">
        <el-input v-model="ruleForm.captchaCode" placeholder="图形验证码："></el-input>
        <img :src="'data:image/jpeg;base64,'+imgSrc" @click="refreshImg" />
      </el-form-item>
      <el-form-item prop="smsCode" class="captchaBox">
        <el-input v-model="ruleForm.smsCode" placeholder="验证码："></el-input>
        <el-button @click="getCode">发送</el-button>
      </el-form-item>
      <el-form-item prop="pass">
        <el-input type="password" v-model="ruleForm.pass" placeholder="密码："></el-input>
      </el-form-item>
      <el-form-item prop="checkPass" class="marBot0">
        <el-input type="password" v-model="ruleForm.checkPass" placeholder="确认密码："></el-input>
      </el-form-item>
      <el-form-item class="marBot0">
        <h2>02 基本信息</h2>
      </el-form-item>
      <el-form-item prop="name">
        <el-input v-model="ruleForm.name" placeholder="姓名："></el-input>
      </el-form-item>
      <el-form-item prop="company">
        <el-input v-model="ruleForm.company" placeholder="医院/单位："></el-input>
      </el-form-item>
      <el-form-item prop="job">
        <el-input v-model="ruleForm.job" placeholder="职称："></el-input>
      </el-form-item>
      <el-form-item>
        <div @click="changeDomains" class="domainsBox">
          <span class="title" :class="domainList.length > 0 ? 'checkedDomain' : ''">{{domainList.length > 0 ? '已选择：':'关注领域：'}}</span>
          <span class="domainList">
            <span v-for="item in domainList" :key="item.id">{{item.name}}</span>
          </span>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">完成注册</el-button>
      </el-form-item>
    </el-form>

    <div class="domainsDialog" v-show="domainsDialog">
      <img src="@/assets/img/goback_icon.svg" @click="domainsDialog = false">
      <el-checkbox-group v-model="checkedDomains" @change="handleCheckedDomainsChange">
        <el-checkbox v-for="item in domainsCheck" :label="item.id" :key="item.id" @change="labelChecked(item)">{{item.name}}</el-checkbox>
      </el-checkbox-group>
      <el-button @click="domainsDialog = false">确认</el-button>
    </div>
  </div>
</template>
<script>
import { getCaptchaImage, sendRegisterSms, getDomains, register } from "@/api/getData";
export default {
  name: "Register",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      imgSrc: '',
      ruleForm: {
        mobile: '',
        pass: '',
        checkPass: '',
        captchaCode: '',
        smsCode: '',
        name: '',
        company: '',
        job: '',
        domains: []
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        captchaCode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入医院', trigger: 'blur' }
        ],
        job: [
          { required: true, message: '请输入职称', trigger: 'blur' }
        ],
        domains: [
          { required: true, message: '请选择关注领域', trigger: 'blur' }
        ]
      },
      domainsDialog: false,
      checkedDomains: [],
      domainsCheck: [],
      domainList: []
    }
  },
  methods: {
    //选择/取消check
    labelChecked(domain) {
      if (this.domainList.indexOf(domain) != -1) {
        this.domainList.splice(this.domainList.indexOf(domain), 1)
      } else {
        this.domainList.push(domain)
      }
      console.log(this.domainList)
    },
    handleCheckedDomainsChange() {
      // console.log(this.checkedDomains)
    },
    //关注领域dialog
    changeDomains() {
      this.domainsDialog = true
    },
    //图形验证码
    getCaptchaImage() {
      getCaptchaImage().then(res => {
        this.imgSrc = res
      })
    },
    //领域列表
    getDomains() {
      getDomains().then(res => {
        console.log(res)
        this.domainsCheck = res.result
      })
    },
    //刷新图形验证码
    refreshImg() {
      this.getCaptchaImage()
    },
    //提交注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            mobile: this.ruleForm.mobile,
            password: this.ruleForm.pass,
            smsCode: this.ruleForm.smsCode,
            name: this.ruleForm.name,
            company: this.ruleForm.company,
            job: this.ruleForm.job,
            domains: this.checkedDomains
          }
          register(data).then(res => {
			      console.log(res)
						this.$message.success('注册成功')
						this.$store.commit('SET_vToken', res.result.token)
            this.$store.commit('SET_vName', res.result.name)
            this.$store.commit('SET_vCompany', res.result.company)
            this.$store.commit('SET_vJob', res.result.job)
            this.$store.commit('SET_vDomains', res.result.domains)
            this.$store.commit('SET_vUserId', res.result.userId)
						this.$router.replace('/')
          })
        } else {
          return false;
        }
      });
    },
    //获取短信验证码
    getCode() {
      this.$refs.ruleForm.validateField(['captchaCode'], (val) => {
        if (!val) {
          //   return true;
          //sendRegisterSms
          let data = {
            captchaKey: this.vVerifycode,
            captchaCode: this.ruleForm.captchaCode,
            mobile: this.ruleForm.mobile
          }
          sendRegisterSms(data).then(res => {
            console.log(res)
          })
        } else {
          return false;
        }
      })
    }
  },
  mounted() {
    this.getCaptchaImage()
    this.getDomains()
  }
}
</script>

<style lang="scss" scoped>
.register {
  padding: 0 0.16rem;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;
  h2 {
    font-size: 0.18rem;
    margin: 0.4rem 0;
  }
  .domainsBox {
    height: .34rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    border-bottom: 1px solid #e5e7e6;
    color: #C2C4C8;
    .checkedDomain{
      color: #000;
    }
    .domainList{
      span{
        display: inline-block;
        height: 0.22rem;
        line-height: 0.22rem;
        border: 1px solid #24BBD3;
        color: #24BBD3;
        font-size: 0.1rem;
        margin-left: .08rem;
        border-radius: 0.02rem;
        padding: 0 0.07rem;
      }
    }
  }
  .domainsDialog {
    left: 0;
    top: 0;
    img {
      display: block;
      width: 0.31rem;
      height: 0.31rem;
      padding: 0.16rem 0;
    }
    .el-checkbox-group {
      margin-bottom: 1rem;
      :deep(.el-checkbox) {
        width: 100%;
        margin-left: 0.2rem;
        height: 0.56rem;
        line-height: 0.56rem;
        font-size: 0.18rem;
        .el-checkbox__label {
          font-size: 0.18rem;
        }

        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #24BBD3;
        }
      }
    }
  }
}
</style>