<template>
  <div class="college">
    <img src="@/assets/img/logo.svg" class="logo">
    <!-- <h2>知见学院</h2> -->
    <router-link to="/orthopaedic" class="orthopaedicBox">
      <img src="@/assets/img/orthopaedic_icon.png">
      <h2>创伤骨科</h2>
    </router-link>
    <router-link to="/rehabilitation" class="rehabilitationBox">
      <img src="@/assets/img/rehabilitation_icon.png">
      <h2>智能康复</h2>
    </router-link>
  </div>
</template>
<script>

export default {
  name: "college",
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.college {
  padding: 0 0.16rem;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  .logo {
    display: block;
    width: 0.84rem;
    padding: 0.32rem 0 0.24rem;
  }
  .orthopaedicBox {
    background: #64cee0;
    height: 1.76rem;
    margin-top: 0.24rem;
    border-radius: 0.1rem;
    box-shadow: 0 .04rem .1rem 0 rgba(255, 255, 255, .3), .08rem .14rem .2rem .04rem rgba(88, 165, 238, .14), 0 .02rem .08rem .02rem rgba(37, 199, 225, .2), .06rem .08rem .14rem .01rem rgba(36, 156, 211, .08);
  }
  .rehabilitationBox {
    background: #5ecfc4;
    height: 1.76rem;
    margin-top: 0.24rem;
    border-radius: 0.1rem;
    box-shadow: 0 .04rem .1rem 0 rgba(255, 255, 255, .3), .08rem .14rem .2rem .04rem rgba(71, 199, 187, .14), 0 .02rem .08rem .02rem rgba(95, 207, 196, .2), .06rem .08rem .14rem .01rem rgba(95, 207, 196, .08);
  }
  .orthopaedicBox,
  .rehabilitationBox {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 1rem;
      height: 1rem;
      margin-right: 0.3rem;
    }
    h2 {
      font-size: 0.36rem;
      color: #fff;
      line-height: 1;
    }
  }
}
</style>