function setRem() {
	let rem, rootWidth;
	let rootHtml = document.documentElement;
	//限制展现页面的最小宽度
	rootWidth = rootHtml.clientWidth;
	// 3.75 = 设计图尺寸宽 / 100（ 设计图的rem = 100 ）
	rem = rootWidth / 3.75;
	// 动态写入样式
	rootHtml.style.fontSize = `${rem}px`;
}

setRem()

window.onresize = function () {
	setRem()
}