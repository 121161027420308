import {
    mapGetters
} from "vuex";

export const mixin = {

    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(['vToken', 'vVerifycode', 'vName', 'vCompany', 'vJob','vDomains','vUserId']),
    },
    methods: {
        
    }
}