export function is_weixn() {
    //平台、设备和操作系统
    let system = {
        win: false,
        mac: false,
        xll: false
    }
    //检测平台
    let p = navigator.platform
    system.win = p.indexOf('Win') === 0
    system.mac = p.indexOf('Mac') === 0
    system.x11 = (p === 'X11') || (p.indexOf('Linux') === 0)
    if (system.win || system.mac || system.xll) {
        return false
    } else {
        let ua = navigator.userAgent.toLowerCase()
        return ua.match(/MicroMessenger/i) == 'micromessenger'
    }
}