import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "../views/Home/Home";  //首页，存放公共tabbar
import Appver from "../views/Appver/Appver";	//版本
import LoginPwd from '../views/Login/LoginPwd.vue'	//登录
import LoginSms from '../views/Login/LoginSms.vue'	//登录
import Register from '../views/Register/Register.vue'   //注册
import ResetPwd from '../views/ResetPwd/ResetPwd.vue'	//找回密码
import College from '../views/College/College.vue'      //知见学院
import Download from '../views/Download/Download.vue'	//资料下载	
import My from '../views/My/My.vue'		//个人中心
import store from '../store'

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		redirect: '/college',
		children: [
			{
				path: '/college',
				name: 'College',
				component: College,
				meta: {
					title: '知见学院'
				}
			},
			{
				path: '/download',
				name: 'Download',
				component: Download,
				meta: {
					title: '资料下载'
				}
			},
			{
				path: '/my',
				name: 'My',
				component: My,
				meta: {
					requireAuth: true,
					title: '个人中心'
				}
			},
			{
				path: '/orthopaedic',
				name: 'Orthopaedic',
				component: () => import('@/views/Orthopaedic/Orthopaedic'),
				meta: {
					title: '创伤骨科'
				}
			},
			{
				path: '/rehabilitation',
				name: 'Rehabilitation',
				component: () => import('@/views/Rehabilitation/Rehabilitation'),
				meta: {
					title: '智能康复'
				}
			},
			{
				path: '/rehabilitationDetails',
				name: 'RehabilitationDetails',
				component: () => import('@/views/Rehabilitation/RehabilitationDetails'),
				meta: {
					title: '智能康复'
				}
			},
			{
				path: '/medicalDetails',
				name: 'MedicalDetails',
				component: () => import('@/views/Orthopaedic/MedicalDetails'),
				meta: {
					requireAuth: true,
					title: '医疗术式'
				}
			}
		]
	},
	{
		path: '/login-pwd',
		name: 'LoginPwd',
		component: LoginPwd,
		meta: {
			title: '密码登录'
		}
	},
	{
		path: '/login-sms',
		name: 'LoginSms',
		component: LoginSms,
		meta: {
			title: '短信登录'
		}
	},
	{
		path: '/register',
		name: 'Register',
		component: Register,
		meta: {
			title: '注册'
		}
	},
	{
		path: '/reset-pwd',
		name: 'ResetPwd',
		component: ResetPwd,
		meta: {
			title: '找回密码'
		}
	},
	{
		path: '/modifyData',
		name: 'ModifyData',
		component: () => import('@/views/Others/ModifyData'),
		meta: {
			title: '编辑基本信息'
		}
	},
	{
		path: '/disclaimer',
		name: 'Disclaimer',
		component: () => import('@/views/Others/Disclaimer'),
		meta: {
			title: '免责声明'
		}
	},
	{
		path: '/setting',
		name: 'Setting',
		component: () => import('@/views/Others/Setting'),
		meta: {
			title: '设置'
		}
	},
	{
		path: '/changePassword',
		name: 'ChangePassword',
		component: () => import('@/views/Others/ChangePassword'),
		meta: {
			title: '修改密码'
		}
	},
	{
		path: '/appver',
		name: 'Appver',
		component: Appver,
		meta: {
			title: 'Appver'
		}
	}
];

const router = new VueRouter({
	routes
});

//页面打开之前
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	if (to.meta.requireAuth) {
		if (store.state.vToken) {
			next()
		} else {
			next('/login-pwd')
		}
	} else {
		if (to.path === '/login-pwd' && store.state.vToken) {
			next(from.path)
		} else {
			next()
		}
	}
});

//router path重复异常处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

export default router;