<template>
  <div class="resetPwd">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
      <el-form-item class="marBot0">
        <h2>找回密码</h2>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input v-model="ruleForm.mobile" placeholder="注册手机号："></el-input>
      </el-form-item>
      <el-form-item prop="captchaCode" class="captchaBox">
        <el-input v-model="ruleForm.captchaCode" placeholder="图形验证码："></el-input>
        <img :src="'data:image/jpeg;base64,'+imgSrc" @click="refreshImg" />
      </el-form-item>
      <el-form-item prop="smsCode" class="captchaBox">
        <el-input v-model="ruleForm.smsCode" placeholder="验证码："></el-input>
        <el-button @click="getCode">发送</el-button>
      </el-form-item>
      <el-form-item prop="pass">
        <el-input type="password" v-model="ruleForm.pass" placeholder="新密码："></el-input>
      </el-form-item>
      <el-form-item prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkPass" placeholder="确认密码："></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getCaptchaImage, sendResetSms, resetPwd } from "@/api/getData";
export default {
  name: "ResetPwd",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      imgSrc: '',
      ruleForm: {
        mobile: '',
        pass: '',
        checkPass: '',
        captchaCode: '',
        smsCode: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        captchaCode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    //图形验证码
    getCaptchaImage() {
      getCaptchaImage().then(res => {
        this.imgSrc = res
      })
    },
    //刷新图形验证码
    refreshImg() {
      this.getCaptchaImage()
    },
    //提交注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            mobile: this.ruleForm.mobile,
            password: this.ruleForm.pass,
            smsCode: this.ruleForm.smsCode
          }
          resetPwd(data).then(res => {
            console.log(res)
            this.$message.success('重置成功')
            this.$store.commit("Logout");
            this.$router.replace('/login-pwd')
          })
        } else {
          return false;
        }
      });
    },
    //获取短信验证码
    getCode() {
      this.$refs.ruleForm.validateField(['captchaCode'], (val) => {
        if (!val) {
          //   return true;
          let data = {
            captchaKey: this.vVerifycode,
            captchaCode: this.ruleForm.captchaCode,
            mobile: this.ruleForm.mobile
          }
          sendResetSms(data).then(res => {
            console.log(res)
          })
        } else {
          return false;
        }
      })
    }
  },
  mounted() {
    this.getCaptchaImage()
  }
}
</script>

<style lang="scss" scoped>
.resetPwd {
  padding: 0 0.16rem;
  h2{
    font-size: 0.18rem;
    margin: 0.4rem 0;
  }
}
</style>