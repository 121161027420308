<template>
  <div class="login">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
      <el-form-item class="marBot0">
        <h2>账号登录</h2>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input v-model="ruleForm.mobile" @focus="show=false" @blur="show=true" placeholder="手机号："></el-input>
      </el-form-item>
      <el-form-item prop="pass">
        <el-input type="password" v-model="ruleForm.pass" @focus="show=false" @blur="show=true" placeholder="密码："></el-input>
      </el-form-item>
      <el-form-item prop="disclaimer" class="marBot16">
        <el-checkbox-group v-model="ruleForm.disclaimer">
          <el-checkbox>我已阅读并同意<router-link to="/disclaimer">免责条款</router-link></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">登 录</el-button>
      </el-form-item>
    </el-form>

    <div class="tab" v-show="show">
      <router-link to="/login-sms">手机验证登陆</router-link>
      <router-link to="/register">新用户注册</router-link>
      <router-link to="/reset-pwd">忘记密码</router-link>
    </div>
  </div>
</template>
<script>
import { LoginPwd } from "@/api/getData"

export default {
  name: "LoginPwd",
  data() {
    return {
      ruleForm: {
        mobile: '',
        pass: '',
        disclaimer: [],
      },
      show: true,
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ],
        disclaimer: [
          { type: 'array', required: true, message: '请查看免责条款', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            mobile: this.ruleForm.mobile,
            password: this.ruleForm.pass
          }
          LoginPwd(data).then(res => {
            this.$message.success('登录成功')
            this.$store.commit('SET_vToken', res.result.token)
            this.$store.commit('SET_vName', res.result.name)
            this.$store.commit('SET_vCompany', res.result.company)
            this.$store.commit('SET_vJob', res.result.job)
            this.$store.commit('SET_vDomains', res.result.domains)
            this.$store.commit('SET_vUserId', res.result.userId)
            this.$router.replace('/')
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.login{
  padding: 0 0.16rem;
  overflow: hidden;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;
  h2{
    font-size: 0.18rem;
    margin: 0.4rem 0;
  }
  :deep(.el-checkbox__label){
    font-size: 0.12rem;
    padding-left: 0.04rem;
    a{
      color: #24BBD3;
    }
  }
  .tab{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    a{
      display: block;
      width: 33.3%;
      text-align: center;
      font-size: 0.12rem;
      color: #A8A8A8;
      line-height: 0.4rem;
      font-weight: normal;
    }
  }
}
</style>