<template>
  <div class="download main-wrap">
    <div v-show="!downloadTips">
      <div class="filter" @click="showFilterDialog">
        <img src="@/assets/img/filter.svg" draggable="false" alt="">
        <span>筛选</span>
        <div class="split-line"></div>
        <div :class="queryStr ? 'placeholder queryStr' : 'placeholder'">
          {{ queryStr ? queryStr : '请输入下载资料的关键词' }}
        </div>
        <img src="@/assets/img/search_btn.svg" draggable="false" alt="" class="search-btn">
      </div>
      <el-dialog :visible.sync="showFilter" :show-close="false" @close="getResourcesList">
        <el-input v-model="queryStr" @keyup.native.enter="queryStr && getResourcesList" @input="isInput = true"
                  ref="queryStr" placeholder="请输入下载资料的关键词"></el-input>
        <img src="@/assets/img/search_btn.svg" draggable="false" alt="" @click="queryStr && getResourcesList"
             class="search-btn">
        <el-checkbox-group v-model="labelId" @change="isChange = true">
          <el-checkbox-button v-for="label in resourceLabelList" :key="label.id" :label="label.id">
            {{ label.name }}
          </el-checkbox-button>
        </el-checkbox-group>
      </el-dialog>
      <div class="resources" v-for="resource in resourceList" :key="resource.id">
        <p class="introduce">{{ resource.remark }}</p>
        <div class="label-wrap">
          <button class="label">{{ resource.label }}</button>
        </div>
        <div class="file">
          <img :src="require(`@/assets/img/file_type${resource.fileType}.svg`)" draggable="false" alt="">
          <div>
            <p class="file-name">{{ resource.name }}</p>
            <div class="creation-time">{{ resource.creatDate }}</div>
          </div>
        </div>
        <div class="resources-footer">
          <button @click="collect(resource.id, resource.isisCollection)">
            <img :src="require(`@/assets/img/collection${resource.isisCollection ? '_active' : ''}.svg`)"
                 draggable="false" alt="">
            <span>收藏</span>
          </button>
          <button @click="download(resource)">
            <img src="@/assets/img/download.svg" draggable="false" alt="">
            <span>下载</span>
          </button>
        </div>
      </div>
    </div>
    <div class="download-tips" v-show="downloadTips">
      <img src="@/assets/img/goback_icon.svg" alt="返回" draggable="false" class="back" @click="downloadTips = false">
      <img src="@/assets/img/arrow.svg" alt="" draggable="false" class="arrow">
      <p class="tips">
        1.请点击右上角“ <img src="@/assets/img/more.svg" draggable="false" alt=""> ”<br>2.选择用浏览器打开<br>3.点击下载获取资料
      </p>
    </div>
  </div>
</template>
<script>
import { getResources, getResourcesLabels, resourcesCollect, downloadResource, getResourceType } from '@/api/getData'
import { is_weixn } from '@/utils/tool'

export default {
  name: "Download",
  data() {
    return {
      showFilter: false,//控制搜索dialog是否显示
      queryStr: '',//全文搜索框value
      isInput: false,
      isChange: false,
      downloadTips: false,//无法下载时提示打开浏览器
      labelId: [],//选中的标签id
      resourceList: [],//资料列表
      resourceTypeList: [],//资料类型列表
      resourceLabelList: [],//资料标签列表
    }
  },
  created() {
    this.getResourcesLabelsList()
    this.getResourceTypeList()
  },
  methods: {
    //获取资料列表
    getResourcesList(init) {
      if (init || this.isInput || this.isChange) {
        let params = {
          queryStr: this.queryStr,
          labelId: this.labelId.join()
        }
        getResources(params).then(res => {
          this.resourceList = res.result
          this.resourceList.forEach(item => {
            this.resourceLabelList.forEach(ele => {
              if (item.labelId === ele.id) {
                item.label = ele.name
              }
            })
          })
          this.isInput = this.isChange = false
        }).catch(error => {
          console.log(error)
        })
      }
    },
    //获取资料标签
    getResourcesLabelsList() {
      getResourcesLabels().then(res => {
        this.resourceLabelList = res.result
        this.getResourcesList('init')
      }).catch(error => {
        console.log(error)
      })
    },
    //获取资料类型列表
    getResourceTypeList() {
      getResourceType().then(res => {
        this.resourceTypeList = res.result
      }).catch(error => {
        console.log(error)
      })
    },
    //收藏或取消收藏资料
    collect(resourceId, isisCollection) {
      if (this.$store.state.vToken) {
        resourcesCollect(resourceId).then(() => {
          if (isisCollection) {
            this.$message.success('取消收藏成功')
          } else {
            this.$message.success('收藏成功')
          }
          this.getResourcesList('init')
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.$router.push('/login-pwd')
      }
    },
    //下载资料
    download(resource) {
      if (this.$store.state.vToken) {
        if (is_weixn()) {
          this.downloadTips = true
        } else {
          downloadResource(resource.id).then(res => {
            const type = this.resourceTypeList.find(item => item.id === resource.fileType)
            const blob = new Blob([res.data], { type: type.name })
            const fileName = resource.name + '.' + type.name
            const blobUrl = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.style.display = 'none'
            link.download = fileName
            link.href = blobUrl
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href)
            document.body.removeChild(link)
          }).catch(error => {
            console.log(error)
          })
        }
      } else {
        this.$router.push('/login-pwd')
      }
    },
    //打开搜索dialog并使搜索框获取焦点
    showFilterDialog() {
      this.showFilter = true
      this.$nextTick(() => {
        this.$refs.queryStr.focus()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.download {
  .filter {
    height: .35rem;
    padding: 0 .1rem 0 .16rem;
    margin: .24rem 0;
    font-size: .14rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: .04rem;
    background-color: rgba(225, 231, 231, .5);

    img:first-child {
      width: .13rem;
    }

    span {
      color: rgba(0, 0, 0, .9);
      margin: 0 0.16rem 0 .07rem;
    }

    .split-line {
      width: .01rem;
      height: .3rem;
      background-color: #D8D8D8;
    }

    .placeholder {
      width: 2.03rem;
      color: #B8B8B8;
      margin: 0 .09rem 0 .16rem;
    }

    .queryStr {
      color: rgba(0, 0, 0, .9);
    }

    .search-btn {
      width: .24rem;
    }
  }

  :deep(.el-dialog__wrapper) {
    width: 100%;

    .el-dialog {
      width: 100%;
      margin: 0 !important;

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding: .24rem 0 0 0;

        .el-input {
          width: 3.43rem;
          padding: 0 .16rem;
          margin-bottom: .24rem;

          .el-input__inner {
            height: .38rem;
            font-size: .14rem;
            color: rgba(0, 0, 0, .9);
            border: .01rem solid #D8D8D8;
            border-radius: .04rem;
            padding: 0 .42rem 0 .16rem;
            background-color: rgba(225, 231, 231, .5);
          }

          .el-input__inner:focus {
            border-color: #24BBD3;
          }
        }

        .el-button {
          border-color: rgba(225, 231, 231, .5);
          background-color: rgba(225, 231, 231, .5);
        }

        .search-btn {
          width: .24rem;
          padding: .07rem .16rem .07rem .02rem;
          position: absolute;
          top: .24rem;
          right: .16rem;
        }

        .el-checkbox-group {
          padding: .24rem .16rem 0;
          border-top: .01rem solid rgba(0, 0, 0, .08);

          .el-checkbox-button {
            margin-bottom: .24rem;
          }

          .el-checkbox-button:nth-child(2n) {
            margin-left: .08rem;
          }

          .el-checkbox-button__inner {
            height: .38rem;
            font-size: .14rem;
            padding: .12rem .36rem;
            color: rgba(0, 0, 0, .9);
            border: .01rem solid rgba(225, 231, 231, .5);
            border-radius: .04rem;
            background-color: rgba(225, 231, 231, .5);
            box-shadow: none;
          }

          .el-checkbox-button__inner:hover {
            color: rgba(0, 0, 0, .9);
          }

          .el-checkbox-button.is-checked .el-checkbox-button__inner {
            color: #24bbd3;
            border-color: #24BBD3;
            background-color: rgba(36, 187, 211, .1);
          }
        }
      }
    }
  }
}
</style>