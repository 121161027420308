import { Message } from 'element-ui'

const messages = ['success', 'warning', 'info', 'error']
let messageInstance = null
const resetMessage = (options) => {
	if (messageInstance) {
		messageInstance.close()
	}
	messageInstance = Message(options)
}

messages.forEach(type => {
	Message[type] = options => {
		if (typeof options === 'string') {
			options = {
				message: options
			}
			// 默认配置
			options.duration = 3000
			options.showClose = true
		}
		options.type = type
		return resetMessage(options)
	}
})