import axios from "../request"

// 获取服务器的版本信息
export const getAppver = () => axios.get('https://management.noitom.com.cn/admin2019/appver')

//account

// 获取注册验证码图片
export const getCaptchaImage = () => axios.get(`/public/captcha-image`)
// 发送注册短信验证码
export const sendRegisterSms = (data) => axios.post(`/public/send-sms/register`,data)
// 获取关注领域
export const getDomains = () => axios.get(`/public/domains`)
// 提交注册信息
export const register = (data) => axios.post(`/public/register`,data)
// 使用用户名密码登录
export const LoginPwd = (data) => axios.post(`/public/login/pwd`,data)
// 使用短信验证码登录
export const LoginSms = (data) => axios.post(`/public/login/sms`,data)
// 发送登录短信验证码
export const sendLoginSms = (data) => axios.post(`/public/send-sms/login`,data)
// 发送找回密码短信验证码
export const sendResetSms = (data) => axios.post(`/public/send-sms/reset-pwd`,data)
// 修改密码
export const resetPwd = (data) => axios.post(`/public/reset-pwd`,data)
// 修改用户信息
export const editModifyData = (userId,data) => axios.post(`/users/${userId}/edit`,data)
// 文章列表
export const articleList = () => axios.get(`/articles`)
//文章详情
export const articleDetail = (articleId) => axios.get(`/articles/${articleId}`)
// 修改密码
export const editPwd = (userId,data) => axios.post(`/users/${userId}/edit-pwd`,data)

//文章收藏
export const articlesCollect = (articleId) => axios.post(`/articles/${articleId}/collect`)




//获取资料列表
export const getResources = (params) => axios.get('/resources', {params})

//获取资料标签
export const getResourcesLabels = () => axios.get('/public/labels')

//获取资料类型
export const getResourceType = () => axios.get('/public/resouce-type')

//资料收藏
export const resourcesCollect = (resourceId) => axios.post(`/resources/${resourceId}/collect`)

//下载资料
export const downloadResource = (resourceId) => axios.get(`/resources/${resourceId}`, {
    responseType: 'blob',
    headers: { 'content-type': 'application/stream' }
})

//获取收藏的资料列表
export const getResourcesCollect = () => axios.get('/resources/collection')

//获取收藏的文章列表
export const getArticlesCollect = () => axios.get('/articles/collection')
//获取报告
export const getReportData = () => axios.get('/userData/report-data')