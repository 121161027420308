import axios from 'axios'
import { Message, Loading } from 'element-ui'
import store from '@/store'
import router from "@/router"

const service = axios.create({
	// baseURL: '/nw/nhealth',
	baseURL: 'https://management.noitom.com.cn/admin2019/nhealth/',
	// baseURL: 'http://127.0.0.1:8071',
	timeout: 50000
})

let loading = null

//http request拦截
service.interceptors.request.use(req => {
	loading = Loading.service({
		background: 'rgba(0, 0, 0, 0.7)'
	})
	if (store.state.vToken) {
		req.headers['x-token'] = store.state.vToken
	}
	return req
})

//http response拦截
service.interceptors.response.use(res => {
	loading.close()
	if (res.config.url == '/public/captcha-image') {
		store.commit('SET_vVerifycode', res.headers.verifycode)
	} else if (res.status === 200) {
		const message = res.data.message || '未知错误'
		if (res.data.code === 0 && (res.data.message === '无效用户' || res.data.message === '请先登录')) {
			store.commit('Logout');
			router.replace("/login-pwd");
			Message({
				message: message,
				type: 'error'
			});
			return Promise.reject(new Error(message))
		} else if (res.headers['content-type'] === 'application/octet-stream' && res.data.code === undefined) {
			return res
		} else if (res.data.code !== 0) {
			Message({
				message: message,
				type: 'error'
			});
			return Promise.reject(new Error(message))
		}
	}
	return res.data
})

export default service